
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import MasterLayout from '@/components/MasterLayout.vue';
import PostHeader from '@/components/NonLoggedInComponents/PostHeader.vue';
import PostIntro from '@/components/NonLoggedInComponents/PostIntro.vue';
import PostInfoHeader from '@/components/NonLoggedInComponents/PostInfoHeader.vue';
import PostInfoImg from '@/components/NonLoggedInComponents/PostInfoImg.vue';
import PostContact from '@/components/NonLoggedInComponents/PostContact.vue';
import PostSpacer from '@/components/NonLoggedInComponents/PostSpacer.vue';
import PostCard from '@/components/NonLoggedInComponents/PostCard.vue';
import PostDivider from '@/components/NonLoggedInComponents/PostDivider.vue';
import PostInfoImg2Col from '@/components/NonLoggedInComponents/PostInfoImg2Col.vue';
import J1LGetStarted from '@/components/NonLoggedInComponents/J1LGetStarted.vue';
@Component({
  components: {
    MasterLayout,
    PostHeader,
    PostIntro,
    PostInfoHeader,
    PostInfoImg,
    PostInfoImg2Col,
    PostContact,
    PostCard,
    PostDivider,
    PostSpacer,
    J1LGetStarted
  }
})
export default class DiscountLabels extends Vue {
  $refs!: {
    DefaultActions: HTMLFormElement;
  };
  metaInfo(): any {
    return {
      meta: [
        {
          name: 'description',
          content:
            'Print custom labels for discounts and wholesale promotions. Custom holiday labels - no minimums, order online 24/7 with Just 1 Label. Get started today.'
        }
      ],
      title: 'Custom Discount Labels, Holiday and Promotion Labels'
    };
  }
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  /* Computed */
  /* Async Functions */
  /* Utility Functions */
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
